<template>
  <fieldset>
    <DocFields
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields>
        <DateField
          :modelValue="modelValue.untilDate"
          @update:modelValue="update('untilDate', $event, v$.untilDate)"
          :errors="v$.untilDate.$errors"
          data-qa="validUntil"
        >
          <label class="label label--required">Действует до</label>
        </DateField>
        <MultiSelectField
          :modelValue="modelValue.zu"
          @update:modelValue="updateVector($event, v$.zu)"
          :options="vectors"
          @reload="fetchWithDelay($event)"
          :errors="v$.$error ? v$.zu.$errors : []"
          :itemValidationRule="itemValidationRule"
          keyProp="externalId"
        >
          <label class="label label--required">Земельные участки</label>
          <template #add>Добавить</template>
        </MultiSelectField>
      </template>
    </DocFields>
  </fieldset>
</template>

<script>
import DateField from "../../basic/form/DateField.vue";
import DocFields from "./DocFields.vue";
import DocGPZU from "../../../models/documents/docGPZU";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import { useVectorLandPlot } from "../../../hooks/vector";
import { toRefs } from "vue";
import { helpers, required, minValue } from "@vuelidate/validators";
import useValidator from "./validator";

export default {
  emits: ["update:modelValue", "update:vector"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocGPZU(),
    },
    documentTypeId: {
      type: Number,
      required: true,
    },
    documentRegNum: {
      type: String,
      required: false,
    },
  },
  components: {
    DocFields,
    DateField,
    MultiSelectField,
  },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);

    const { vectors, fetchWithDelay } = useVectorLandPlot();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let rules = {
      untilDate: {
        minValue: helpers.withMessage(
          "Дата должна быть позднее 01.01.1970",
          minValue(new Date(1970, 0, 1))
        ),
        required: helpers.withMessage("Заполните поле", required),
      },
      zu: {
        required: helpers.withMessage("Заполните поле", required),
      },
    };

    let itemValidationRule = {
      externalId: helpers.withMessage(
        "Заполните или удалите неиспользуемое поле",
        (value) => {
          return value !== null && value["externalId"] !== undefined;
        }
      ),
    };

    const v$ = useValidator(data, rules);

    function updateVector(value, field) {
      update("zu", value, field);
      emit("update:vector", value);
    }

    return {
      data,
      update,
      updateVector,
      v$,
      fetchWithDelay,
      vectors,
      itemValidationRule,
    };
  },
};
</script>
